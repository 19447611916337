// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myFar{min-width:auto;min-height:auto}.myFar [data-testid="small-screen-boundary"]{display:none}.myFar header{display:none}.JjK6k{min-height:100vh;padding:32px 24px 64px;background-color:var(--wpp-grey-color-200)}`, "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,eAAA,CAEA,6CACE,YAAA,CAGF,cACE,YAAA,CAIJ,OACE,gBAAA,CACA,sBAAA,CACA,0CAAA","sourcesContent":[".root {\n  min-width: auto;\n  min-height: auto;\n\n  :global([data-testid='small-screen-boundary']) {\n    display: none;\n  }\n\n  header {\n    display: none;\n  }\n}\n\n.container {\n  min-height: 100vh;\n  padding: 32px 24px 64px;\n  background-color: var(--wpp-grey-color-200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `myFar`,
	"container": `JjK6k`
};
export default ___CSS_LOADER_EXPORT___;
